var render = function render(_c,_vm){return _c('v-col',{attrs:{"sm":"12","md":"6","lg":"4","xl":"3","cols":"12"}},[_c('div',{class:_vm.$style.card},[_c('div',{class:_vm.$style.content},[_c('div',{class:_vm.$style.left},[_c('div',{class:_vm.$style.icon},[(_vm.props.photo)?_c('div',{class:_vm.$style.imageDiv,style:({
              backgroundImage: `url(${_vm.props.photo})`,
            })}):_vm._e()])]),_c('div',{class:_vm.$style.right},[_c('div',{class:_vm.$style.adminData},[_c('div',{class:_vm.$style.name},[_c('h2',[_vm._v(_vm._s(`${_vm.props.firstName} ${_vm.props.lastName}`))]),_c('span'),(!_vm.props.disabled)?_c('v-btn',{staticClass:"minimal-btn",on:{"click":() => _vm.props.editDetails(_vm.props.id)}},[_vm._v(_vm._s(_vm.parent.$t('EDIT_DETAILS')))]):_vm._e()],1),_c('h5',{class:[
              _vm.$style.active,
              _vm.props.status === _vm.props.userStatuses.pending
                ? _vm.$style.pending
                : null,
              _vm.props.status === _vm.props.userStatuses.suspended
                ? _vm.$style.suspended
                : null,
            ]},[_vm._v(_vm._s(_vm.props.status === _vm.props.userStatuses.active ? _vm.parent.$t('ACTIVE') : _vm.props.status === _vm.props.userStatuses.suspended ? _vm.parent.$t('SUSPENDED') : _vm.parent.$t('PENDING_USER_ACTIVATION')))])]),_c('div',[_c('div',{class:_vm.$style.mail},[_vm._v(_vm._s(_vm.props.email))])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }