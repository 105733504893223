<script>
export default {
  props: {
    id: {
      type: String,
      default: null,
    },
    firstName: {
      type: String,
      default: ' ',
    },
    lastName: {
      type: String,
      default: ' ',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    photo: {
      type: String,
      default: '',
    },
    email: {
      type: String,
      default: '',
    },
    editDetails: {
      type: Function,
      default: () => null,
    },
    status: {
      type: String,
      default: '',
    },
    userStatuses: {
      type: Object,
      default: () => null,
    },
  },
}
</script>

<template functional>
  <v-col sm="12" md="6" lg="4" xl="3" cols="12">
    <div :class="$style.card">
      <div :class="$style.content">
        <div :class="$style.left">
          <div :class="$style.icon">
            <div
              v-if="props.photo"
              :class="$style.imageDiv"
              :style="{
                backgroundImage: `url(${props.photo})`,
              }"
            ></div>
          </div>
        </div>
        <div :class="$style.right">
          <div :class="$style.adminData">
            <div :class="$style.name">
              <h2>{{ `${props.firstName} ${props.lastName}` }}</h2>
              <span></span>
              <v-btn
                v-if="!props.disabled"
                class="minimal-btn"
                @click="() => props.editDetails(props.id)"
                >{{ parent.$t('EDIT_DETAILS') }}</v-btn
              >
            </div>
            <h5
              :class="[
                $style.active,
                props.status === props.userStatuses.pending
                  ? $style.pending
                  : null,
                props.status === props.userStatuses.suspended
                  ? $style.suspended
                  : null,
              ]"
              >{{
                props.status === props.userStatuses.active
                  ? parent.$t('ACTIVE')
                  : props.status === props.userStatuses.suspended
                  ? parent.$t('SUSPENDED')
                  : parent.$t('PENDING_USER_ACTIVATION')
              }}</h5
            >
          </div>
          <div>
            <div :class="$style.mail">{{ props.email }}</div>
          </div>
        </div>
      </div>
    </div>
  </v-col>
</template>

<style lang="scss" module>
@import '@design';
.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 466px;
  min-height: 160px;
  padding: 20px 0;
  margin: 10px 27px 20px 0;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0 20px $user-card-shadow;

  .content {
    display: flex;
    width: 100%;
    min-width: 450px;
    .left {
      padding: 0 25px 0 30px;
      .icon {
        width: 52px;
        height: 52px;
        margin-top: -5px;
        overflow: hidden;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 52 52'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Ccircle cx='26' cy='26' r='26' fill='%23F2F4F6'/%3E%3Cg fill='%238C949E' fill-rule='nonzero' transform='translate(14.444444 14.444444)'%3E%3Ccircle cx='11.5555556' cy='5.7777778' r='5.7777778'/%3E%3Cpath d='M23.1111382 19.0666667c.0048647-1.129442-.6524574-2.156967-1.679916-2.626-3.1140729-1.3550553-6.4797734-2.0353826-9.8756666-1.9962223-3.3958933-.0391603-6.7615938.641167-9.8756667 1.9962223-1.0274586.469033-1.6847807 1.496558-1.679916 2.626v4.0444444h23.1111653v-4.0444444z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        border-radius: 50px;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .imageDiv {
      width: 100%;
      height: 100%;
      background-repeat: none;
      background-position: center;
      background-size: cover;
      border-radius: 50px;
    }

    .right {
      width: 100%;
      padding-right: 40px;
      .name {
        display: flex;
        h2 {
          font-size: 24px;
          font-weight: 700;
          color: $dark;
          word-break: break-word;
        }
        span {
          flex-grow: 1;
        }
      }

      h5 {
        margin: -3px 0 0 0;
        font-weight: normal;
      }
      .mail {
        padding-left: 27px;
        margin-top: 13px;
        font-size: 15px;
        font-weight: 400;
        color: #8c949e;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 14'%3E%3Cg fill='%238C949E' fill-rule='nonzero'%3E%3Cpath d='M15 0H1C.4 0 0 .4 0 1v1.4l8 4.5 8-4.4V1c0-.6-.4-1-1-1z'/%3E%3Cpath d='M7.5 8.9L0 4.7V13c0 .6.4 1 1 1h14c.6 0 1-.4 1-1V4.7L8.5 8.9c-.28.14-.72.14-1 0z'/%3E%3C/g%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: left center;
        background-size: 16px 14px;
      }
    }
  }

  .active {
    color: $grey;
  }
  .pending {
    color: $deselected;
  }
  .suspended {
    color: $inactive;
  }

  .hidden {
    display: none;
  }
}
</style>
